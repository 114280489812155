let menuSearch = document.querySelector('#search');
let searchPage = document.querySelector('body.search');

if (menuSearch) {
    let searchInput = document.querySelector('#search #search-input');
    let deleteBtn = menuSearch.querySelector('svg');
    
    if(searchInput.value != '') {
        deleteBtn.classList.add('show');
    }

    searchInput.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteBtn.classList.add('show');
        }
        else {
            deleteBtn.classList.remove('show');
        }
    });
    deleteBtn.addEventListener('click', function(e) {
        searchInput.value = '';
        deleteBtn.classList.remove('show');
    });
}

if (searchPage) {
    let pageSearchInput = searchPage.querySelector('#search-page #search-page-input');
    let inputValue = searchPage.querySelector('#content-section #search-page #search-page-input').value;
    pageSearchInput.focus();
    pageSearchInput.value = '';
    pageSearchInput.value = inputValue;

    let deleteBtn = searchPage.querySelector('#search-page svg');
    
    if(pageSearchInput.value != '') {
        deleteBtn.classList.add('show');
    }

    pageSearchInput.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteBtn.classList.add('show');
        }
        else {
            deleteBtn.classList.remove('show');
        }
    });
    deleteBtn.addEventListener('click', function(e) {
        pageSearchInput.value = '';
        deleteBtn.classList.remove('show');
    });
}
